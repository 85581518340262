import React from "react"
import { Helmet } from "react-helmet"
import config from "../../../site.config"
import { graphql } from "gatsby"

/** components */
import Layout from "../../components/LayoutFund"
import { theme } from "../../components/Theme"

/** static sections */
import FundLeadershipTimSection from "../../components/static-sections/FundLeadershipTimSection"

/** dynamic sections */
import HeroInvestImage from "../../components/dynamic-sections/HeroInvestImage"
import HeroVideoFund from "../../components/dynamic-sections/HeroVideoFund"
import HeroInvestImageHero from "../../components/dynamic-sections/HeroInvestImageHero"
import FundRibbon from "../../components/dynamic-sections/FundRibbon"
import HeroBottomIFundTextSection from "../../components/dynamic-sections/HeroBottomIFundTextSection"
import FundServiceStats from "../../components/dynamic-sections/FundServiceStats"

/** svg */
import NorhartBlackCircleIcon from "../../../assets/norhart-black-circle-icon.svg"

/** color theme */
const colorPalette = theme.colorPalettes.invest

/** props */
interface Props {
  data?: any
  didSubmit: boolean
}

/** const */
export const fundServicesSection: React.FC<Props> = (props) => {
  const ldJson = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: {
          "@id": "/fund/services",
          name: "Services| Norhart Fund",
          image: `${config.siteMetadata.siteUrl}/fund/norhart-invest-hero-open-graph.png`,
        },
      },
    ],
  }

  return (
    <Layout
      title="Services| Norhart Fund"
      description=""
      keywords=""
      imageTwitter={`${config.siteMetadata.siteUrl}/fund/norhart-invest-hero-twitter-card.png`}
      imageOpenGraph={`${config.siteMetadata.siteUrl}/fund/norhart-invest-hero-open-graph.png`}
      colorPalette={colorPalette}
    >
      <HeroInvestImageHero imageHero="/fund/oakdale/norhart-oakdale-lobby-justin-bartak-design.png" />

      <Helmet>
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      </Helmet>

      <HeroBottomIFundTextSection
        backgroundColorOne="#f0f0f0"
        backgroundColorTwo="#f0f0f0"
        icon={NorhartBlackCircleIcon}
        iconTitle="BUSINESS SERVICES"
        title="Evolving investment"
        formatSubTitle={() => (
          <>
            Norhart was established with a mission to transform the real estate industry by offering a distinct
            approach. Leveraging our extensive expertise in the accommodation sector, we have effectively managed
            significant capital investments and consistently delivered impressive returns on realized assets.
            <br />
            <br />
            At Norhart, our top priority is to create living spaces that connect with people and inspire them to call it
            home. We understand that fostering this connection is crucial for driving social, environmental, and
            financial benefits for both the communities we serve and the investors we partner with.
            <br />
            <br />
            Our aim is to create better spaces that people can connect with and truly want to live in. We recognize that
            this is essential for generating social, sustainable, and financial value for the communities we invest in,
            and the investors we work with.
          </>
        )}
        subNote=""
        image=""
        imageAlt=""
        textColor="#111111"
        asoEffect="fade-in"
      />

      <FundServiceStats />

      <HeroInvestImageHero imageHero="/fund/oakdale/norhart-oakdale-garage-justin-bartak-design.png" />

      <FundRibbon
        company="NORHART"
        title="Asset Management"
        subtitle="We have a track record of delivering exceptional results. Our investments are not just spaces for people to live – they’re places where real communities grow and thrive.
      
      We work collaboratively with our investors to build ideas and continue to evolve our working practices across the board to create long-term impact and results."
        buttonName=""
        buttonUrl=""
      />

      <HeroInvestImageHero imageHero="/fund/oakdale/norhart-odevelopment.png" />

      <FundRibbon
        company="NORHART"
        title="Development Management"
        subtitle="Norhart's developments are driven by a deep understanding of people's fundamental desires and requirements for spaces. We have the capability to anticipate future residential needs, enabling us to craft spaces that are more adaptive and responsive."
        buttonName=""
        buttonUrl=""
      />

      <HeroInvestImageHero imageHero="/fund/oakdale/norhart-oakdale-workarea-justin-bartak-design.png" />

      <FundRibbon
        company="NORHART"
        title="Investment Management"
        subtitle="Norhart was founded with the mission of revolutionizing the real estate industry by offering a unique approach. Leveraging our deep expertise in the accommodation sector, we have successfully managed significant capital investments and consistently delivered impressive returns on realized assets."
        buttonName=""
        buttonUrl=""
      />

      <HeroInvestImageHero imageHero="/fund/oakdale/norhart-oakdale-rooftop-zen-garden-justin-bartak-design.png" />

      <FundRibbon
        company="NORHART"
        title="Property Management"
        subtitle="All businesses have to adapt and change over time, and at Norhart, we do this by focusing on the real-world needs of our customers. We build solutions ourselves to meet those needs and provide top-quality services in residential and purpose-built housing, community living, development finance, and property technology. Our expertise in these sectors complements each other and helps us meet investor expectations effectively."
        buttonName=""
        buttonUrl=""
      />

      <investmentManagement colorPalette={colorPalette} />
    </Layout>
  )
}

/** export */
export default fundServicesSection

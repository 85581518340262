import React from "react"

interface Props {
  company: string
  title: string
  subtitle: string
  buttonName?: string
  buttonUrl?: string
}

/** const */
const FundServiceStats: React.FC<Props> = (props) => {
  const Icon = props.icon

  return (
    <section className="container-fluid pt-5 pb-5" style={{ background: "#f4f4f4" }}>
      <div className="container pl-1 pr-1">
        <div className="contentHeroSpace hidden-xs"></div>
        <div className="container-fluid p-0 ms-1 me-3 pe-1" style={{ background: "#f4f4f4", color: "#111111" }}>
          <div className="row align-items-start justify-content-start">
            <div className="col-sm-12">
              <div className="mt-1" style={{ backgroundColor: "#dba000", width: "100%", height: "4px" }}></div>
              <div className="mb-3 invest-stats-title">Our Stats</div>
              <div className="mb-3 invest-stats-text" style={{ width: "100%" }}>
                Norhart is committed to changing the construction industry, which has stayed the same for decades.
              </div>

              <div className="container ms-0 p-0">
                <div className="row">
                  <div className="col-sm-4">
                    <div className="invest-stats-numbers">
                      <div className="invest-stats-numbers">15%-43%</div>
                      <div className="invest-stats-text">IRR</div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="invest-stats-numbers">
                      <div className="invest-stats-numbers">1.7-4.2</div>
                      <div className="invest-stats-text">Equity Mutiple</div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="invest-stats-numbers">
                      <div className="invest-stats-numbers">20+</div>
                      <div className="invest-stats-text">Transactions</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 invest-cta-right">{props.subtitle}</div>
          </div>
        </div>
      </div>
    </section>
  )
}

/** export */
export default FundServiceStats
